import * as React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { withLayout } from '../../../components/layout';
import { CenteredImage } from '../../../components/centered_image';
import { H1, H2, H3 } from '../../../components/mdx_blocks/header';
import { StaticImage } from 'gatsby-plugin-image';
import { ContactUsFormModal } from '../../../components/contact_us_form';
import GroundMotionModelingVideo from './resources/sim_mex_1_component_0_output.mp4';

function GroundMotionModeling() {
    return (
        <>
            <H1>
                Accurate Fault Planes in Earthquake Ground Motion Modeling
            </H1>
            <p>
            When modeling teleseismic events, they are often treated as point
            sources – a reasonable simplification at large distances from the
            event. However, these point source approximations are generally
            insufficient to accurately model the resulting ground motion
            produced by ruptures on large, nearby fault planes. The modeling of
            these ruptures is of particular importance in the context of
            seismic hazard and assessing expected ground motion produced by
            such events.
            </p>
            <p>
            Standard Rupture Format (SRF) (<a
              href="http://equake-rc.info/static/paper/SRF-Description-Graves_2.0.pdf"
              target="_blank"
            >Graves, 2014</a>)
            files are often used to define complex seismic sources on fault
            planes. This format allows the user to save a complete kinematic
            description of the fault rupture. The fault surface is sub-divided
            into subfaults, which are each represented by a point source –
            each of which has its own kinematic description of its contribution
            to the overall fault rupture. SRF also allows for the description
            of complex fault geometries.
            </p>
            <p>
            Working together with researchers from{' '}<a
              href="https://cos.ethz.ch/"
              target="_blank"
            >ETH Zurich</a>{' '}and the{' '}<a
              href="https://www.bsc.es/research-and-development/research-areas/geophysics"
              target="_blank"
            >Barcelona supercomputing Center</a>{' '}
            (BSC), SRF sources have been integrated into Salvus, allowing users
            to directly read SRF files into Salvus and use them to simulate
            local fault ruptures and the resulting ground motion. An example is
            shown here for the 2017 Mexico Puebla earthquake.
            </p>
            <H2>
              2017 Mexico Puebla Earthquake
            </H2>
            <p>
            The magnitude 7.1 Puebla earthquake occurred 120 km from Mexico
            City, and was particularly destructive due to high shaking
            intensity, resulting in 370 deaths, thousands of injuries and
            billions of dollars of damage to infrastructure. In this example,
            we read in an SRF file describing the rupture on the fault plane,
            simulate the wave propagation in the full volume, and record the
            surface motion at a receiver in Mexico City.
            </p>

            <CenteredImage
                caption={[
                    <i>{' '}(left){' '}</i>,
                    "Spectral-element mesh with surface topography and a seismic station in Mexico City (shown in red).",
                    <i>{' '}(right){' '}</i>,
                    "Station in Mexico City (shown in red) relative to the fault plane (shown in purple).",
                    <br></br>
                ]}
            >
                <Grid doubling columns={2}>
                    <Grid.Column>
                        <StaticImage
                            src="./resources/Mexico_City_receiver.png"
                            alt="Spectral elment mesh with topography and a station."
                            width={800}
                            quality={100}
                            placeholder="tracedSVG"
                        />
                    </Grid.Column>
                    <Grid.Column>
                        <StaticImage
                            src="./resources/all_sources.png"
                            alt="Station and fault plane."
                            width={800}
                            quality={100}
                            placeholder="tracedSVG"
                        />
                    </Grid.Column>
                </Grid>
            </CenteredImage>
            <H2>
                Visualizing the Fault and Earthquake Mechanism
            </H2>
            <p>
            Once the SRF file has been read into Salvus, we can clearly
            visualize the many point sources that approximate the motion on the
            fault plane. Here we can see: (a) the individual focal mechanisms
            for each point source, representing how that subsection of the
            fault moves during the fault rupture; (b) the initiation time of
            each subfault, showing how the rupture propagates across the
            larger fault surface through time; (c) the slip resulting from
            each of these point sources, which is notably concentrated in some
            areas of the fault plane. In this example, one can also see that
            some areas on the fault plane do not slip.
            </p>
            <CenteredImage
              caption={`
              Different ways of visualizing the earthquake sources distributed
              along the fault plane. (top): moment tensor source mechanisms,
              (middle): onset times, (bottom): slip.
              `}
            >
              <StaticImage
                src="./resources/srf_sources_triplot.png"
                alt="Visualizing the sources on the fault plane."
                width={1200}
                quality={100}
                placeholder="tracedSVG"
              />
            </CenteredImage>
            <p>
            This slip can also be visualized in the fault plane as shown below:
            </p>
            <CenteredImage
              caption={`
              Slip distribution along the fault plane.
              `}
            >
              <StaticImage
                src="./resources/srf_sources.png"
                alt="Slip distribution along the fault plane."
                quality={100}
                width={1200}
                placeholder="tracedSVG"
              />
            </CenteredImage>

            <H2>
                Animation
            </H2>
            <p>
            We are now able to simulate the forward wavefield resulting from
            this complex fault rupture, including the influence of topography,
            which can have a significant impact on ground shaking. The video
            below shows how the fault plane ruptures, as well as the
            propagation of the wavefield, with the color representing velocity.
            </p>
            <Container css={{ paddingLeft: '1ex', paddingRight: '1ex', paddingBottom: '2em' }}>
                <video
                    playsInline
                    autoPlay
                    muted
                    loop
                    css={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '75%',
                    }}
                >
                    <source src={GroundMotionModelingVideo} type="video/mp4" />
                </video>
            </Container>
            <H2>
                Acknowledgements
            </H2>
            <p>
            This work has received funding from the European Union's Horizon
            Europe research and innovation programme under grant agreement no.
            <b>101058129</b> (DT-Geo), working towards constructing digital
            twins to estimate and analyze the impacts of seismic events.
            Visit the
            {' '}<a
                  href="https://dtgeo.eu"
                  target="_blank"
              >
            DT Geo website
            </a>{' '} for more information. We also gratefully acknowledge
            the {' '}<a
                  href="https://www.sbfi.admin.ch/"
                  target="_blank"
              >
              Swiss State Secretariat for Education, Research and Innovation
              </a>{' '}
            for their support.
            </p>
            <CenteredImage
            >
              <StaticImage
                src="./resources/dt_geo_logo.png"
                alt="DT GEO Logo."
                quality={100}
                placeholder="tracedSVG"
              />
            </CenteredImage>

            <Grid style={{ marginTop: '5ex' }}>
              <Grid.Row centered>
              The Salvus software suite is Mondaic's flagship code to solve problems like
              the above
              </Grid.Row>
              <Grid.Row centered>
              ... and many more!
              </Grid.Row>
              <Grid.Row centered>
              <ContactUsFormModal />
              </Grid.Row>
            </Grid>
        </>
    );
}

export default withLayout(GroundMotionModeling);
